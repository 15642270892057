.embla {
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}

.embla__container {
  display: flex;
  align-items: flex-start;
  user-select: none;
}

.embla__slide {
  min-width: 100%;
}

.embla__slide__inner {
  position: relative;
  overflow: hidden;
  aspect-ratio: 1982 / 1191;
  width: 100%;
  max-height: calc(100vh - var(--nextui--navbarHeight) - 130px);
}

.embla__slide__img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  max-height: 100%;
  transform: translate(-50%, -50%);
}

.embla--thumb {
  padding-top: var(--nextui-space-lg);
}

.embla__container--thumb {
  cursor: default;
}

.embla__slide--thumb {
  min-width: 20%;
  padding-left: var(--nextui-space-lg);
}

.embla__slide--thumb:first-child {
  padding-left: 0;
}

.embla__slide__inner--thumb {
  touch-action: manipulation;
  cursor: pointer;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  height: 100px;
  background-color: transparent;
  position: relative;
  display: block;
  overflow: hidden;
}

.embla__slide__thumbnail {
  position: absolute;
  opacity: 0.5;
  top: 0;
  bottom: 0;
  left: -10000%;
  right: -10000%;
  margin: auto;
  min-width: 1000%;
  min-height: 1000%;
  max-width: none;
  transform: scale(0.1);
  transition: opacity 0.2s;
}

.embla__slide__thumbnail:hover {
  opacity: 0.6;
}

.embla__slide--thumb.is-selected .embla__slide__thumbnail {
  opacity: 1;
}
